/* eslint-disable */
class FullSlider {
    constructor(_element = null, _options = {}) {
        this.element = _element
        this.options = {
            ...{
                autoSlide: true,
                duration: 5000
            },
            ..._options
        }

        this.slides = [...this.element.querySelectorAll('.js-slide')]
        this.prevBtn = this.element.querySelector('.js-slide-prev')
        this.nextBtn = this.element.querySelector('.js-slide-next')

        this.autoSlideInterval = null

        this.init()
    }

    init() {
        if (this.options.autoSlide) {
            this.autoSlideInterval = setInterval(this.goNext.bind(this), this.options.duration)
        }

        this.addEvents()
    }

    goPrev() {
        const activeSlide = this.element.querySelector('.is-active')
        const prevSlide = activeSlide.previousElementSibling
        activeSlide.classList.remove('is-active')
        if (prevSlide) {
            prevSlide.classList.add('is-active')
            return;
        }

        const slidesLength = this.slides.length - 1
        const lastSlide = this.slides[slidesLength]
        lastSlide.classList.add('is-active')
    }

    goNext() {
        const activeSlide = this.element.querySelector('.is-active')
        const nextSlide = activeSlide.nextElementSibling
        const nextSlideClassList = [...nextSlide.classList]
        activeSlide.classList.remove('is-active')
        if (nextSlideClassList.indexOf('js-slide') > 0) {
            nextSlide.classList.add('is-active')
            return;
        }

        this.slides[0].classList.add('is-active')
    }

    addEvents() {
        this.prevBtn.addEventListener('click', () => {
            this.goPrev()

            if (this.autoSlideInterval) {
                clearInterval(this.autoSlideInterval)
            }
        })

        this.nextBtn.addEventListener('click', () => {
            this.goNext()

            if (this.autoSlideInterval) {
                clearInterval(this.autoSlideInterval)
            }
        })
    }
}


export const enhancer = () => {
// window.addEventListener("DOMContentLoaded", () => {

    const fullSliders = [...document.querySelectorAll('.js-full-slider')]
    if (fullSliders.length) {
        for (let element of fullSliders) {
            new FullSlider(element)
        }
    }
// })
};
